@import "~@vismaux/nordic-cool/dist/css/nc.min.css";

html {
  height: 100%;
}

body {
  padding-top: 0px !important;
  min-width: 320px;
  height: 100%;
}

.margin-top {
  margin-top: 20px;
}

.margin-right {
  margin-right: 20px;
}

.margin-right-sm {
  margin-right: 10px;
}

.margin-left {
  margin-left: 20px;
}

.no-margin-bottom {
  margin-bottom: 0px;
}

.no-margin-right {
  margin-right: 0px;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}

.allign-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
