div.LoadingSpinner {
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  object {
    text-align: center;
    display: block;
    position: absolute;
    top: 35%;
    left: 35%;
    height: 30%;
    width: 30%;
  }
}
