div.Menu {
  div.vertical-nav {
    z-index: 9999;
    top: 0px !important;
    height: 100% !important;
    h1 {
      text-align: center;
      margin: 10px 0px;
      color: #fff;
    }
    hr {
      margin: 0px;
    }
    li.show-nav {
      bottom: 50px !important;
    }
  }

  div.container.no-padding {
    max-width: 95%;
  }
}
